import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import logo from '../../src/images/NDLogo.png';

const Layout = ({ children, pageName, page }) => {
    return (
        <>
            <Helmet>
                <title>NavaDhiti</title>
                <link rel="icon" href={logo} />
            </Helmet>
           
            <Header pageName={pageName} />
            {children}
            <Footer />
        </>
    );
};

export default Layout;
