import React from 'react';
import Layout from '../components/Layout';
import LandingPage from '../pages/LandingPage/index';

const page = '#our-capabilities';

const index = () => {
    return (
        <>
            <Layout pageName={page}>
                <LandingPage />
            </Layout>
        </>
    );
};
export default index;
